*{
font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
background-color: #222831;
color: #EEEEEE;
}

.main-h{
  font-size: 3rem;
  font-weight: bold;
}

.main-p{
  font-size: 1.5rem;
}

.App{
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.App-header{
  text-align: center;
}

.img-working{
  width: 80%;
  height: 400px;
}